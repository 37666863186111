
import React, { Component } from "react"

import Helmet from "react-helmet"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Clutch from "../components/clutch"
import Slider from "react-slick"

import Culture_phone from "../images/culture_phone_img.png"
import Culture_slide from "../images/culture_slide_img.jpg"
import Culture_slide2 from "../images/culture_slide_img-02.jpg"

import Arrow_down from "../images/arrow_down.png"

import Chatur_carlos from "../images/chatur_carlos.png"
import Magento_icon from "../images/m1tom2.png"
import Shopify_icon from "../images/m1toshopify.png"

import "animate.css/animate.min.css"
import ScrollAnimation from 'react-animate-on-scroll'


const culture__slider = {
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    autoplay: true,
    cssEase: 'linear'
};



export default class Container extends Component  {
  componentDidMount() {
  }
  render() {

  return (

  <Layout>
    <Helmet>
      <title>CueBlocks Team - Ecommerce Experts behind the Scenes</title>
      <meta name="description"
            content="CueBlocks has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
      <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
      <link rel="canonical" href="https://www.cueblocks.com/team.php"/>
      <meta name="description"
            content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
      <meta property="og:site_name" content="Cueblocks"/>
      <meta property="fb:app_id" content="289086684439915"/>
      <meta property="og:url" content="https://www.cueblocks.com/team.php"/>
      <meta property="og:title"
            content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
      <meta property="og:description"
            content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
      <meta property="og:image" content="https://www.cueblocks.com/images/cb.jpg"/>

      <meta name="twitter:card" content="summary_large_image"/>
      <meta name="twitter:description"
            content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
      <meta name="twitter:title"
            content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
      <meta name="twitter:site" content="@cueblocks"/>
      <meta name="twitter:image" content="https://www.cueblocks.com/images/cb.jpg"/>

    </Helmet>
    <section className="home-slider__row">
 
      <div className="container">
        <h3>Not a Run of the Mill eCommerce Agency…</h3>
          <ScrollAnimation animateIn="fadeInUp" animateOnce="true">
        <h1>
              <span>You</span> Ideate. <span>We</span> Create!
        </h1>
        </ScrollAnimation>
        <ul>
          <li>
            <Link to="/magento-1-to-magento-2-migration-service">
            <img src={Magento_icon} alt="Magento1 to Magento2 Migration" />
            <p>Magento 1 to Magento 2</p>
            </Link>
          </li>
          <li>
            <div className="talkdesign">
              <Link to="/contact">
               <span>Let's</span>
              <br />
              <span>Talk eCommerce</span>
              </Link>
            </div>
            <a href="#homewhat" className="movedown">
              <img src={Arrow_down} alt="Arrow down" />
            </a>
          </li>
          <li>
            <Link to="/magento-1-to-shopify-migration-service">
            <img src={Shopify_icon} alt="Magento1 to Shopify Migration" />
            <p>Magento 1 to Shopify</p>
            </Link>
          </li>
        </ul>
        

      </div>
    </section>
    <section className="home-what__we_do" id="homewhat">
      <div className="container">
        <div className="home-what__we_left pd_right_40">
          <h2 className="heading_main">What we do</h2>
        
          <p>
            We blend your design, coding, and marketing needs with contemporary eCommerce concepts to create a positive ROI for your brand.
            <span>#madebycueblocks</span>
          </p>
        </div>
        <div className="home-what__we_right pd_left_40">
          <p>
            With the ever-changing technology, we get how important it is for brands, especially in today’s day and age, to be able to listen every minute of every day 
            and react almost instantly. We work with you to make your vision a reality because we understand that a whole lot has changed in the last decade than in 
            the past century. With our team of highly skilled individuals, we strive to give you the best of Design, Marketing, Development, Audit, and Consulting services.
            <Link to="/services" className="read_btn">
              Read more..
            </Link>
          </p>
        </div>
      </div>
    </section>
    <section className="home-culture__row">
      <div className="container">
        <div className="culture__left">
          <p>allthatgrows.in</p>
          <img src={Culture_phone} alt="All that grows" />
        </div>
        <div className="culture__right pd_left_40">
          <div className="fade slider">
            <Slider {...culture__slider}>
              <div>
                <img src={Culture_slide} alt="Culture at Cueblocks1" />
                <div className="slide_caption">culture @cueblocks</div>
              </div>
              <div>
                  <img src={Culture_slide2} alt="Culture at Cueblocks2" />
                <div className="slide_caption">culture @cueblocks</div>
              </div>
              <div>
                  <img src={Culture_slide} alt="Culture at Cueblocks3" />
                <div className="slide_caption">culture @cueblocks</div>
              </div>
              <div>
                  <img src={Culture_slide2} alt="Culture at Cueblocks4" />
                <div className="slide_caption">culture @cueblocks</div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </section>
    <section className="home-weare__row">
      <div className="container">
        <div className="weare__rt pd_left_40">
          <h2 className="heading_main">Who we are</h2>
          <p>
            A team of highly adept professionals, operating in global time zones, we strive to provide stellar E-commerce and Digital Marketing Services that are focused on an ROI-driven approach. 
            <Link to="/about" className="read_btn">
              Read more..
            </Link>
          </p>
          <Link to="/career" className="main_cta">
            work with us
          </Link>
        </div>
        <div className="weare__lt">
          <h2>Feed us with</h2>
          
          <img
            style={{ verticalAlign: "top" }}
            src={Chatur_carlos}
            alt="Chatur and Carlos"
          />
          <p>Chatur & Carlos</p>
           
        </div>
      </div>
    </section>
    <section className="home-blog_case__row" style={{ verticalAlign: "top" }}>
      <div className="container">
        <div className="blog_case__block">
          <div className="blog__col">
            <h3 className="heading_main">case studies</h3>
            <h4>
              ALLTHATGROWS
              <label className="label__g label__m">SHOPIFY</label>
            </h4>
            <p>
              Building brand identity, packaging designs & marketing campaigns for AllThatGrows.
            </p>
            <Link to="/atg_brand" className="read_btn">
              Read more..
              </Link>
          </div>
          <div className="case__col">
            <h3 className="heading_main">case studies</h3>
            <h4>
              PETDOORS
              <label className="label__g label__m">magento</label>
            </h4>

            <p>
             Merging revenue-driven blogs to increase organic sessions by 106.94% for PetDoors.
                 
            </p>
            <Link to="/merging-blog-pages-seo" className="read_btn">
              Read more..
              </Link>
          </div>
        </div>
      </div>
    </section>
    <section className="home-resourse__row">
      <div className="container">
        <h2 className="heading_main">Resources</h2>
        <div className="resourse__lt pd_right_40">
          <p>
            Be sure to check off all the points on this specially-curated Conversion Rate Optimisation (CRO) before investing in your next big eCommerce venture. 
          </p>
          <h3>91 Point CRO Checklist & Infographic</h3>
          <a href={'/Holy-Grail-of-eCommerce-Conversion-Optimization-91-Point-Checklist-and-Infographic.pdf'} className="main_cta" download>
            Download
          </a>
        </div>
        <div className="resourse__rt pd_left_40">
          <p>
           Follow our 40-Point Checklist Guide for quick tips & feasible steps to smoothly switch the HTTP version of your website to a more secure version, HTTPS.
          </p>
          <h3>40 Point Checklist for a Successful HTTP to HTTPS Migration </h3>
          <a href={'/blog-Infographics.pdf'} className="main_cta" download>
            Download
          </a>
        </div>
      </div>
    </section>
    <Clutch />
  </Layout>
  )
}
}
